<template>
  
  <v-container
    fill-height
    fluid
    :style="{ backgroundImage: `url(${imgBg})` }"
    class="container_bg"
  >
    <v-layout
      align-center
      justify-center
      style="left: 0; right: 0; margin: auto; height: 80vh"
    >
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar :color="color">
            <v-toolbar-title>{{ loginData[locale].title }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                ref="username"
                v-model="username"
                :rules="[() => !!username || loginData[locale].requiredField]"
                prepend-icon="mdi-account"
                :placeholder="loginData[locale].placeholderLogin"
                required
                autofocus
              />
              <v-text-field
                ref="password"
                v-model="password"
                :rules="[() => !!password || loginData[locale].requiredField]"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :placeholder="loginData[locale].placeholderPassword"
                counter
                required
                @keydown.enter="loginByPassword"
                @click:append="showPassword = !showPassword"
              />
            </v-form>
          </v-card-text>
          <v-divider class="mt-5" />
          <v-card-actions>
            <v-spacer />
            <v-btn
              align-center
              justify-center
              color="general"
              class="mr-2"
              @click="loginByPassword"
              >{{ loginData[locale].buttonLogin }}
            </v-btn>
            <v-divider class="mx-5" inset vertical></v-divider>
            <v-btn @click="loginBySign">
              Вход по ЭЦП
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="snackbar" :color="color" :top="true">
            <v-card>
              <v-card-title>
                <span class="headline">{{ snackbarTitle }}</span>
              </v-card-title>
              <v-card-text>
                {{ loginData[locale].errorMessages }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark text @click="snackbar = false">
                  {{ loginData[locale].buttonClose }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-snackbar>
          <v-card
          class="pa-5"
          >
            <v-row>
              <v-col>
                {{ supportText }}
              </v-col>
              <v-col>
                <v-row>
                  <a :href="emailLink">{{ email }} </a>
                </v-row>
                <v-row>
                  {{ phone }} 
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ncalayer from "@/services/ncalayer/ncalayer";
import imgBg from "@/assets/img/footer-bg.jpg";

export default {
  components: {
    supportInfo: () => import("@/layouts/public/widgets/Support.vue")
  },
  data: function() {
    return {
      cert: "",
      successRedirectURL: "/app/dashboard",
      imgBg: imgBg,
      username: "",
      password: "",
      snackbar: false,
      color: "general",
      showPassword: false,
        locale: "ru",
      snackbarTitle: "",
      loginData: {
        ru: {
          title: "Авторизация",
          labelLogin: "Имя пользователя",
          labelPassword: "Пароль",
          placeholderLogin: "Введите имя пользователя",
          placeholderPassword: "Введите пароль",
          requiredField: "Обязательное поле",
          errorMessages: "Неправильное имя пользователя или пароль",
          buttonLogin: "Вход",
          buttonClose: "Закрыть",
          loginType: ["Логин/Пароль", "ЭЦП"],
          loginTypeLabel: "Вход по"
        },
        en: {
          title: "ISUN",
          labelLogin: "Login",
          labelPassword: "Password",
          placeholderLogin: "Enter username",
          placeholderPassword: "Enter password",
          requiredField: "This field is required",
          errorMessages: "Incorrect login info",
          buttonLogin: "Login",
          buttonClose: "Close",
          loginType: ["Login/Password", "DigitalSignature"],
          loginTypeLabel: "Log in by"
        }
      },
      phone: "+7 (7172)574 482",
      email: "isun@iacng.kz",
      emailLink: "mailto:isun@iacng.kz",
      supportText: "Техническая поддержка"
    };
  },
  watch: {
    color(val) {
      this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary = val;
    }
  },

  methods: {
    loginByPassword: function() {
      let username = this.username;
      let password = this.password;
      this.$store
        .dispatch("auth/loginByPassword", { username, password })
        .then(response => {
            if (response.status == "200" && response.text != "") {
              this.snackbarTitle = "Произошла ошибка"
              this.loginData[this.locale].errorMessages = response.data.text;
              this.snackbar = true;
              return;
          }
            if (response.status == "Info" && response.text != "") {
              this.snackbarTitle = "Внимание"
              this.loginData[this.locale].errorMessages = response.data.text;
              console.log(response);
          }
          //Проверяем роль пользователя
            if (response.data.firstLogon == true) {
                this.$router.push("/app/user/profile/changepass").catch(err => {
                    console.log(err.message);
                });
            } else if (response.data.role === "Customer") {
                this.$router.push("/app/customerpage").catch(err => {
                    console.log(err.message);
                });
            } else {
              this.$router.push(this.successRedirectURL).catch(err => {
                    console.log(err.message);
                });
            }
        })
        .catch(err => {
          this.snackbarTitle = "Внимание"
          this.loginData[this.locale].errorMessages = "Не удалось выполнить запрос. Попробуйте еще раз.";
          this.snackbar = true;
        });
    },
    loginBySign: async function() {
      const cert = await ncalayer.sign();
      console.log();
      this.$store
          .dispatch("auth/loginByDigitalSignature", cert)
          .then(() => {
          // Получаем текущую роль пользователя из геттеров
          const userRole = this.$store.getters["auth/userRole"];

          // Задаем URL для перенаправления в зависимости от роли
          let redirectURL = "";
          if (userRole === "Customer") {
            redirectURL = "/app/customerpage";
          } else {
            redirectURL = this.successRedirectURL;
          }

          // Перенаправляем пользователя
          this.$router.push(redirectURL);
        })
        .catch(err => {
          this.snackbarTitle = "Ошибка";
          this.loginData[this.locale].errorMessages = err.response.data.data.text;
          this.snackbar = true;
          console.log(err.response);
        });
    },
    // sign: async function() {
    //   const result = await ncalayer.sign();
    //   console.log();
    //   return result;
    //   // this.loginBySign(result);
    // },
    showMessage(message) {
      this.loginData[this.locale].errorMessages = message;
      this.snackbar = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-text-field >>> label {
  font-size: 1.2em;
  color: red;
}
a {
  color: inherit;
  text-decoration: none;
}
.container_bg {
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
